.App {
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
}
.navbar {
  background-color: white !important;
  border-bottom: 5px solid green;
}

.navbar-nav {
  width: 100%;
}

.navbar-brand img {
  width: 150px;
}

.navbar li {
  font-size: 1.5rem;
}

.navbar-item {
  align-content: left;
}

.dropdown-menu {
  max-width: 100%;
  background-color: rgb(0, 78, 0);
}

.dropdown-item {
  font-size: 1.5rem;
  color: white;
}

.dropdown-item.active {
  background-color: rgb(0, 78, 0);
  color: rgb(193, 236, 193);
}

.dropdown-item:active {
  background-color: rgb(0, 78, 0);
}

.dropdown-item:hover {
  background-color: rgb(0, 78, 0);
  color: rgb(193, 236, 193);
}

.dropdown a {
    cursor: pointer;
    pointer-events: auto;
}

.footer {
  color: black;
  padding: 15px;
}
.social-media-contact{
  height: 50px;
  margin: 10px;
}

.social-media-footer {
  height: 50px;
  margin: 5px;
}

.footer p {
  margin-left: 20px;
  margin-bottom: 0;
}

.bunny {
  width: 100px;
  opacity: 0.6;
}

.page.pricelist {
  text-align: center;
  width: 80%;
  margin-left: 10%;
  border: black 3px solid;
  border-radius: 25px;
  padding: 50px 0;
  font-size: 1.1em;
}

.pricelist td {
  text-align: left;
  padding-left: 10%;
  width: 90%;
}

table td {
 width: 100%;
}

table {
  width: 100%;
}

.pricelist td.small-price {
  text-align: right;
  padding-left: 0;
  padding-right: 10%;
}

.picture-banner div {
  display: flex;
}

.picture-banner img {
  width: 100%;
  height: 100%;
}

.page {
  width: 95%;
  margin-left: 2.5%;
  font-family: 'American Typewriter', serif;
}

.logo {
  margin: 25px;
  width: 40%;
}

.welcome {
  width: 90%;
  font-size: 1.25em;
  margin-left: 5%;
  text-align: center;
  margin-bottom: 25px;
  line-height: 2em;
  color: rgb(117, 117, 117);
}

@media (max-width: 768px) {
  .picture-banner {
    width: 0;
    height: 0;
  }

  .welcome {
     font-size: 1em;
     text-align: justify;
  }

  .page .types p {
    font-size: 1em;
  }

  .page .square p {
    font-size: 1em;
  }

  .page h1 {
    font-size: 2em;
  }
  
  .page h3 {
    font-size: 1.75em;
  }

  .page.pricelist {
    border: none;
    font-size: 0.8em;
  }

  .page.pricelist p {
    text-align: left;
  }

}
 
.small img{
  width: 100%;
}

.small {
  margin: 30px 0;
}

.square img {
  width: 100%;
}

.square div {
  width: 70%;
  margin-top: 10%;
  margin-left: 15%;
}

.square p {
  width: 85%;
  margin-top: 10%;
  margin-left: 7.5%;
  font-size: 1.25em;
  text-align: justify;
  padding: 0 3%;
}

.types h3 {
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.types p {
  font-size: 1.25em;
  text-align: justify;
  margin: 0 10% 2.5% 10%;
}

.nav-link.dropdown-toggle {
  background-color: transparent;
  border: none;
}


.contact-disclaimer {
  color: black;
}

.social-media-contact {
  height: 50px;
  margin: 10px;
}

.form-col {
  width: 80%;
  margin-left: 10%;
}

.social-media-row {
  margin-top: 10px;
}

.contact-box {
  border: 2px rgb(0, 0, 0) solid;
  border-radius: 15px;
  background-color: rgb(131, 131, 131);
  margin-bottom: 5%;
  margin-top: 5%;
  width: 80%;
  padding: 20px;
  color: white;
}

.contact {
  width: 80%;
  height: 450px;
}

.contact-p {
  padding: 20px;
}

.btn-send {
  background-color: black;
  color: white;
  border: none;
  border-radius: 15px;
}

.form-control {
  border-radius: 10px;
}

.benefits {
  text-align: left;
}

.welcome h4{
  font-weight: 500;
  color: rgb(43, 43, 43);
}

.welcome h3 {
  color: black;
}

.dark {
  color: rgb(43, 43, 43);
  margin-bottom: 0;
}

.pricelist h3 {
 text-align: center;
 width: 100%;
}